import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
  Grid,
  Select,
  MenuItem as SelectOption,
} from '@material-ui/core'
import { FormHelperText } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  agencySelector,
  requestTypeSelector,
  responsibleTeamSelector,
} from '_/modules/agency/selectors'
import { useParams } from '@reach/router'
import { Input } from '@refera/ui-web'
import { getAgency, getOptionsFieldName } from '_/modules/agency/actions'

import Button from '_components/button'
import { updateAppointmentDateSchedule } from '_modules/service-orders/actions'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'

import useStyles from './styles'
import moment from 'moment'
import useRolePermission from '_/hooks/use-role-permission'

const VisitScheduleModal = ({ onClose }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const responsibleTeamOptions = useSelector(responsibleTeamSelector)
  const requestTypeOptions = useSelector(requestTypeSelector)
  const { isAdmin } = useRolePermission()
  const agency = useSelector(agencySelector)

  // Custom Fields
  const responsibleTeam = agency?.customFields?.toJS().includes('responsible_team')
  const requestType = agency?.customFields?.toJS().includes('request_type')

  useEffect(() => {
    dispatch(getAgency(serviceOrder?.agency?.get('id')))
    dispatch(getOptionsFieldName(serviceOrder?.agency?.get('id')))
  }, [dispatch, serviceOrder?.agency?.get('id')])

  const defaultValues = useMemo(() => {
    const newDate = serviceOrder?.visitSchedule ? new Date(serviceOrder?.visitSchedule) : new Date()

    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset())

    return {
      dateAndTime: newDate,
      externalId: serviceOrder?.externalId ? serviceOrder?.externalId : '',
      responsibleTeam: serviceOrder?.responsibleTeam ? serviceOrder?.responsibleTeam : '',
      requestType: serviceOrder?.requestType ? serviceOrder?.requestType : '',
    }
  }, [
    serviceOrder?.visitSchedule,
    serviceOrder?.externalId,
    serviceOrder?.responsibleTeam,
    serviceOrder?.requestType,
  ])

  const [selectedDate] = useState(defaultValues.dateAndTime)
  const [selectedExternalId, setSelectedExternalId] = useState(defaultValues.externalId)
  const [selectedResponsibleTeam, setSelectedResponsibleTeam] = useState(
    defaultValues.responsibleTeam
  )
  const [selectedRequestType, setSelectedRequestType] = useState(defaultValues.requestType)
  const [hasError, setHasError] = useState(false)

  const showCustomFields = selectedResponsibleTeam === 'quinto_andar' || isAdmin

  const handleSubmit = () => {
    const payload = {
      dateAndTime: moment(selectedDate)?.format(),
      externalId: selectedExternalId,
    }

    if (responsibleTeam) {
      payload.responsibleTeam = selectedResponsibleTeam
    }

    if (requestType) {
      payload.requestType = selectedRequestType
    }

    if (selectedResponsibleTeam === 'quinto_andar') {
      if (Object.values(payload).every(x => x !== '')) {
        setHasError(false)
        dispatch(updateAppointmentDateSchedule(serviceOrderId, payload))
      } else {
        setHasError(true)
      }
    }

    if (selectedResponsibleTeam !== 'quinto_andar') {
      if (
        payload.responsibleTeam !== '' &&
        payload.requestType !== '' &&
        payload.dateAndTime !== ''
      ) {
        setHasError(false)
        dispatch(updateAppointmentDateSchedule(serviceOrderId, payload))
          .then(() => onClose())
          .catch(() => onClose())
      } else {
        setHasError(true)
      }
    }
  }

  return (
    <Dialog open classes={{ paper: styles.dialog }}>
      <DialogTitle className={styles.dialogTitle}>
        <Box mt={5} />
        <Typography variant="h6">Dados da imobiliária</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={styles.dialogContent}>
        <Grid className={styles.modalColumn}>
          {responsibleTeam && showCustomFields && (
            <>
              <Grid className={styles.selectGrid}>
                <Typography className={styles.labels}>
                  Equipe responsável<b className={styles.required}> *</b>
                </Typography>
                <Select
                  style={{ fontSize: 14, minWidth: '100px', color: '#696969' }}
                  onChange={event => setSelectedResponsibleTeam(event.target.value)}
                  value={selectedResponsibleTeam}
                  required
                >
                  {responsibleTeamOptions
                    ?.sort((x, y) => (x.option < y.option ? -1 : 1))
                    ?.map(object => (
                      <SelectOption key={object.value} value={object.value}>
                        {object.option}
                      </SelectOption>
                    ))}
                </Select>
              </Grid>
              {!selectedResponsibleTeam && hasError && (
                <FormHelperText className={styles.required}>Campo requerido</FormHelperText>
              )}
            </>
          )}
          <>
            <Input
              label="Código da intermediária"
              style={{ fontSize: 14, color: '#696969' }}
              name="externalId"
              onChange={e => setSelectedExternalId(e.target.value)}
              value={selectedExternalId}
              required={selectedResponsibleTeam === 'quinto_andar'}
              fullWidth
            />
            {!selectedExternalId && !selectedResponsibleTeam === 'quinto_andar' && hasError && (
              <FormHelperText className={styles.required}>Campo requerido</FormHelperText>
            )}
          </>
          {requestType && showCustomFields && (
            <>
              <Grid className={styles.selectGrid}>
                <Typography className={styles.labels}>
                  Tipo da solicitação<b className={styles.required}> *</b>
                </Typography>
                <Select
                  style={{ fontSize: 14, minWidth: '100px', color: '#696969' }}
                  onChange={event => setSelectedRequestType(event.target.value)}
                  value={selectedRequestType}
                  required
                >
                  {requestTypeOptions
                    ?.sort((x, y) => (x.option < y.option ? -1 : 1))
                    ?.map(object => (
                      <SelectOption key={object.value} value={object.value}>
                        {object.option}
                      </SelectOption>
                    ))}
                </Select>
              </Grid>
              {!selectedRequestType && hasError && (
                <FormHelperText className={styles.required}>Campo requerido</FormHelperText>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={styles.dialogAction}>
        <Button color="red" variant="outlined" className={styles.button} onClick={onClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={() => handleSubmit()}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

VisitScheduleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default React.memo(VisitScheduleModal)
